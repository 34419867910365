import { render, staticRenderFns } from "./MessageSettings.vue?vue&type=template&id=45e8a9b6&scoped=true&"
import script from "./MessageSettings.vue?vue&type=script&lang=js&"
export * from "./MessageSettings.vue?vue&type=script&lang=js&"
import style0 from "./MessageSettings.vue?vue&type=style&index=0&id=45e8a9b6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e8a9b6",
  null
  
)

export default component.exports