<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="消息设置"
      class="px-5 py-3 mb-5"
    >
      <div class="title-tip">
        设置会员在微信上收到的消息及内容。
        <br>会员如果48小时以上没有主动发消息给公众号（包括发送信息、点击自定义菜单、订阅事件、扫描二维码事件、支付成功事件、用户维权）,将不会收到相关消息。<v-btn
          small
          color="warning"
          dark
        >
          详细说明
        </v-btn>
      </div>
      <div class="floor">
        <v-subheader style="font-size:15px;">
          选择类型
        </v-subheader>
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="primary"
            dark
          >
            <v-tab
              v-for="item in items"
              :key="item.id"
            >
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-col cols="12">
              <v-row
                align="center"
                class="grey lighten-5 pa-3"
              >
                <v-chip-group
                  v-model="selection"
                  active-class="deep-purple accent-4 white--text"
                  column
                >
                  <v-chip
                    v-for="(item, index) in list.list"
                    :key="index"
                    label
                  >
                    {{ item.name }}
                  </v-chip>
                </v-chip-group>
              </v-row>
            </v-col>
          </v-tabs-items>
        </v-card>
      </div>
      <div class="floor">
        <v-subheader style="font-size:15px;">
          消息编辑
        </v-subheader>
        <div class="d-flex flex-row mb-2">
          <div class="iphone">
            <div class="iphone-header">
              <i class="diy-phone-receiver" />
              <div class="diy-phone-title" />
            </div>
            <div class="diy-phone-contain">
              <div class="materialPrePanel">
                <div class="dt">
                  <p
                    class="single-summary"
                    style="padding:10px;"
                  >
                    {{ contentList.content.text }}
                  </p>
                </div>
              </div>
            </div>
            <div class="diy-phone-footer" />
          </div>
          <div class="ml-5 messagePanel">
            <div class="flex-row">
              <div class="mr-4">
                <span style="color:red;font-size:15px;">*</span>
                消息内容：
              </div>
              <div
                id="editor"
                style="width:350px;height:100%"
              />
              <v-card
                class="ml-5 mt-0 mr-0"
                max-width="400"
                tile
              >
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(item, i) in contentList.content.contentList"
                      :key="i"
                      @click="insertClick(item.name)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </div>
            <div class="mt-5">
              <v-container fluid>
                <v-radio-group
                  v-model="contentList.content.isWx"
                  row
                >
                  <template v-slot:label>
                    <div style="color:#000">
                      <span style="color:red;font-size:15px;">*</span>
                      微信发送
                    </div>
                  </template>
                  <v-radio :value="true">
                    <template v-slot:label>
                      <div style="color:#000">
                        开启
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <div style="color:#000">
                        关闭
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-radio-group
                  v-model="contentList.content.isSMS"
                  row
                >
                  <template v-slot:label>
                    <div style="color:#000">
                      <span style="color:red;font-size:15px;">*</span>
                      短信发送
                    </div>
                  </template>
                  <v-radio :value="true">
                    <template v-slot:label>
                      <div style="color:#000">
                        开启
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <div style="color:#000">
                        关闭
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-radio-group
                  v-model="contentList.content.isLetter"
                  row
                >
                  <template v-slot:label>
                    <div style="color:#000">
                      <span style="color:red;font-size:15px;">*</span>
                      站内信发送
                    </div>
                  </template>
                  <v-radio :value="true">
                    <template v-slot:label>
                      <div style="color:#000">
                        开启
                      </div>
                    </template>
                  </v-radio>
                  <v-radio :value="false">
                    <template v-slot:label>
                      <div style="color:#000">
                        关闭
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-container>
            </div>
          </div>
        </div>
        <v-divider />
        <div class="text-center">
          <v-btn
            color="green"
            class="mr-4 mt-4"
            @click="submit"
          >
            保存
          </v-btn>
        </div>
      </div>
    </base-material-card>
  </div>
</template>

<script>
  import WangEditor from 'wangeditor'
  import itemContent from '../../json/contentList'
  export default {
    data () {
      return {
        tab: 0,
        items: [
          { id: 1, tab: '会员', content: 'Tab 1 Content' },
          { id: 2, tab: '分销代理', content: 'Tab 2 Content' },
          { id: 3, tab: '订单', content: 'Tab 3 Content' },
          { id: 4, tab: '佣金', content: 'Tab 4 Content' },
          { id: 5, tab: '营销', content: 'Tab 5 Content' },
          { id: 6, tab: '其他', content: 'Tab 6 Content' },
        ],
        itemsContent: itemContent.list,
        selection: 0,
        editor: '',
        editorobj: null,
        insertName: '', // 插入名称
        row: 'radio-1',
      }
    },
    computed: {
      list () {
        const id = this.items[this.tab].id
        console.log(this.itemsContent.find(item => item.pid === id))
        return this.itemsContent.find(item => item.pid === id)
      },
      contentList () {
        return this.list.list[this.selection]
      },
    },
    watch: {
      contentList (n, o) {
        console.log(n, o)
        this.selection = 0
        if (n !== undefined) {
          this.editor.txt.html('<p>' + n.content.text + '</p>') // 重新设置编辑器内容
        }
      },
    },
    mounted () {
      const editor = new WangEditor('#editor')
      console.log(editor)
      editor.config.menus = [
        'link',
      ]
      editor.config.showFullScreen = false
      editor.config.height = 200
      // editor.config.height = 800
      editor.create()
      editor.txt.html('<p>' + this.contentList.content.text + '</p>') // 重新设置编辑器内容
      this.insertName = this.contentList.content.text
      // editor.config.onchange = (newHtml) => {
      //   console.log('999', newHtml)
      //   this.editorData = newHtml
      // }
      this.editor = editor
    },
    methods: {
      submit () {},
      insertClick (name) {
        console.log(name)
        this.insertName = this.insertName + name
        this.editor.txt.html('<p>' + this.insertName + '</p>') // 重新设置编辑器内容
      },
    },
  }
</script>

<style lang="scss" scoped>
.title-tip {
  padding: 10px;
  background-color: #e6f7ff;
  color: #666;
  margin-top: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}
.iphone {
  width: 345px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background: #fff;
  .diy-phone-receiver {
    margin: 13px auto;
    width: 98px;
    height: 34px;
    display: block;
    background: url('../../assets/phone.png') no-repeat;
    background-position: 0 -74px;
  }
  .diy-phone-title {
    width: 320px;
    height: 64px;
    padding-top: 32px;
    color: #fff;
    font-size: 18px;
    overflow: hidden;
    border: 1px solid #22292c;
    border-bottom: 0;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    background: url('../../assets/phone.png') no-repeat;
    background-position: 0 0;
    text-align: center;
  }
  .diy-phone-contain {
    margin: 0 auto;
    width: 320px;
    min-height: 400px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    overflow: hidden;
    .materialPrePanel {
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      width: 300px;
      margin-top: 20px;
      .dt {
        padding: 0;
        background-color: #fff;
        .single-summary {
          padding: 10px 0;
          line-height: 18px;
          color: #666;
          border: 1px solid #e7e7eb;
          font-size: 14px;
        }
      }
    }
  }
  .diy-phone-footer {
    margin: 10px auto;
    width: 62px;
    height: 61px;
    display: block;
    background: url('../../assets/phone.png') no-repeat;
    background-position: -253px -70px;
  }
}
.messagePanel {
  position: relative;
  border: 1px solid #e7e7eb;
  background-color: #f4f5f9;
  padding: 30px 30px 30px 10px;
}
.flex-row {
  display: flex;
}
.align-self-start {
  display: flex;
  align-self: flex-start;
}
</style>
